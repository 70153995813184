import axios from 'axios';

export default (name, phone, email, message) => {
  const url = `${process.env.REACT_APP_API_HOST}/contact`;

  const data = {
    name,
    phone,
    email,
    message,
  };

  return axios.post(url, data);
};
