import theme from 'theme';
import styled from 'styled-components';

export default {
  Input: styled.div`
    display: flex;
    position: relative;
    font-family: ${theme.texts.types.SEMIBOLD};
    font-size: ${theme.texts.sizes.S};
    border: ${theme.unit(0.04)} solid ${theme.colors.GRAY_2};
    background-color: ${theme.colors.WHITE};
    justify-content: flex-start;
    align-items: center;
    padding: ${theme.unit(0.19)};

    ::before {
      font-family: "Purpo";
      content: ${props => `"${props.icon}"`};
      color: ${props => props.iconColor || theme.colors.GRAY_1};
    }

    ::after {
      content: ${props => (props.error ? `"${props.error}"` : '')};
      position: absolute;
      font-size: ${theme.unit(0.3)};
      bottom: -${theme.unit(0.45)};
      left: 0px;
      color: #f00;
      text-transform: uppercase;
    }

    input {
      position: relative;
      top: 2px;
      background: none;
      border: none;
      width: 100%;
      margin-left: ${theme.unit(0.09)};

      ::placeholder {
        transition-timing-function: ease;
        transition: opacity 0.2s;
      }

      :focus {
        ::placeholder {
          opacity: 0;
        }
      }
    }

    ${props => props.styles}
  `,
};
