import styled from 'styled-components';

export default {
  ImageGrid: styled.div`
    line-height: 0;
    column-count: 5;
    column-gap: 0px;
    margin-top: 20px;
  `,
  Image: styled.img`
    cursor: pointer;
    width: 100%;
    max-width: 500px;
    height: auto;
    opacity: 0.8;
    transition: opacity .2s;

    :hover {
      transition: opacity .5s;
      opacity: 1;
    }
  `,
  FileInput: styled.label`
    position: absolute;
    border: none;
    text-align: center;
    width: 170px;
    padding: 5px 10px;
    border-radius: 3px;
    background: #5d5db1;
    color: #fff;
    cursor: pointer;

    ${props => (props.visible
    ? `
      transition: opacity .3s ease-in-out .3s, visibility 0s;
      opacity: 1;
      visibility: initial;
    `
    : `
      transition: opacity .3s ease-in-out, visibility 0s .3s;
      opacity: 0;
      visibility: hidden;
    `)}

    input {
      display: none;
    }
  `,
  FileActions: styled.div`
    width: 170px;
    display: flex;
    justify-content: space-between;

    ${props => (props.visible
    ? `
      transition: opacity .3s ease-in-out .3s, visibility 0s;
      opacity: 1;
      visibility: initial;
    `
    : `
      transition: opacity .3s ease-in-out, visibility 0s .3s;
      opacity: 0;
      visibility: hidden;
    `)}

    button {
      cursor: pointer;
      border: none;
      padding: 5px 10px;
      border-radius: 3px;
      background: #5d5db1;
      color: #fff;
      :nth-child(2) {
        background: #eee;
        color: #555;
      }
    }
  `,
};
