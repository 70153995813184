import axios from 'axios';
import secureConfig from './secure-config';

export default {
  save(content) {
    const url = `${process.env.REACT_APP_API_HOST}/content`;

    return axios.post(url, { content: JSON.stringify(content) }, secureConfig());
  },

  read() {
    const url = `/content.json`;

    return axios.get(url);
  },
};
