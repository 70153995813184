import React, { Component } from 'react';
import PostService from 'services/post';
import moment from 'moment';
import styles from './styles';

class ListPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      processing: true,
    };

    this.getAll = this.getAll.bind(this);
  }

  componentDidMount() {
    this.getAll();
  }

  getAll() {
    PostService.getAll().then((res) => {
      this.setState({ posts: res.data, processing: false });
    });
  }

  toRoute(route) {
    return () => {
      this.props.history.push(route);
    };
  }

  deletePost(post) {
    return () => {
      if (window.confirm(`Seguro que desea borrar el post '${post.title}'`)) {
        this.setState({ processing: true });
        PostService.delete(post.id)
          .then(() => {
            this.getAll();
            alert(`eliminado el post '${post.title}'`);
          })
          .catch(() => {
            this.setState({ processing: false });
            alert('Error al eliminar intente de nuevo');
          });
      }
    };
  }

  render() {
    if (this.state.processing) return <div>Cargando</div>;

    return (
      <div>
        <styles.Button type="button" value="Agregar" onClick={this.toRoute('/admin/blog/create')} />
        <h3>Listado de Post</h3>
        <styles.Table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Titulo</td>
              <td>Creacion</td>
              <td>Ultima Actualizacion</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {this.state.posts.map((e, i) => (
              <tr key={i}>
                <td>{e.id}</td>
                <td>{e.title}</td>
                <td>{moment(e.createdAt).format('DD - MM - YYYY LT')}</td>
                <td>{moment(e.updatedAt).format('DD - MM - YYYY LT')}</td>
                <td>
                  <a
                    href={`/blog/${e.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="show"
                  >
                    Ver
                  </a>
                  <styles.Button
                    type="button"
                    value="Actualizar"
                    onClick={this.toRoute(`/admin/blog/update/${e.id}`)}
                  />
                  <button type="button" onClick={this.deletePost(e)} className="delete">
                    eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </styles.Table>
      </div>
    );
  }
}

export default ListPost;
