import theme from 'theme';
import styled from 'styled-components';

export default {
  DivFullScreen: styled.div`
    @media only screen and (min-width: 800px) {
      height: 100vh;
    }

    width: 100vw;
    min-height: ${theme.unit(19)};
    overflow: hidden;
    display: flex;
    background-color: ${theme.colors.WHITE};
    ${props => props.opacityWhite && 'background-color: rgba(255,255,255,.5);'}
    ${props => props.opacityPurple && 'background-color: rgba(150, 40, 100,.7);'}
    ${props => props.flexEnd && 'justify-content: flex-end;'}
    flex-direction: ${props => (props.column ? 'column' : 'row')};
    align-items: bottom;
    margin-top: auto;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;

    :after {
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      height: ${theme.unit(2.5)};
      z-index: 1;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      height: auto;

      :after {
        height: ${theme.unit(1)};
    }

      .animated {
        text-align: center;

        p {
          padding: 20px;
        }
      }
    }
  `,
  ServicesLoop: styled.div`
    height: ${theme.texts.sizes.M};
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    font-size: ${theme.texts.sizes.XS};

    .Cursor {
      display: none;
    }
  `,
  AboutContainer: styled.div`
    position: relative;
    width: 100%; // 60%
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.unit(1)};
    height: ${theme.unit(15)};

    @media (max-width: 800px) {
      width: 100%;
      height: auto;
    }
  `,
  CarouselTopMargin: styled.div`margin-top: ${theme.unit(1)};`,
  CarouselContainer: styled.div`
    display: none;
    position: relative;
    width: 40%;
    background: rgba(0, 0, 0, 0.7);
    text-align: left;
    padding: ${theme.unit(1)} 0px;
    border-left: solid 5px ${theme.colors.YELLOW_1};
    height: ${theme.unit(15)};

    @media (max-width: 800px) {
      width: 100%;
      border: none;

      .carousel .slider-wrapper {
        max-width: 500px;
      }

      .carousel .control-dots {
        bottom: 0px;
        margin: 0px;
        margin: 0px;
        padding: 0px;
      }
    }
  `,
  CarouselTitle: styled.div`
    margin-left: ${theme.unit(1)};

    @media (max-width: 800px) {
      margin-left: auto;
      text-align: center;

      span {
        font-family: ${theme.texts.types.BOLD};
        font-size: ${theme.texts.sizes.L};
        color: ${theme.colors.YELLOW_1};
      }
    }
  `,
  CarouselDescription: styled.div`
    height: ${theme.unit(5)};
    padding: ${theme.unit(0.5)} ${theme.unit(1)};
    text-align: left;
  `,
  ServicesTitle: styled.div`
    width: 100%;
    padding-top: ${theme.unit(0.4)};
    padding-bottom: ${theme.unit(0.3)};
    background: ${theme.colors.BLACK};
    text-align: center;
  `,
  ServiceTitle: styled.div`
    height: ${theme.unit(3)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `,
  ServicesContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
    }
  `,
  Service: styled.div`
    width: 50%;
    position: relative;
    
    @media only screen and (max-width: 800px) {
      width: 100vw;
      
      :nth-child(1){
        margin-bottom: ${theme.unit(0.3)};
      }
    }

    div.animated {
      background: ${props => `url(${props.image})`};
      background-size: cover;
      background-repeat: no-repeat;
      ${props => (props.right ? 'background-position:right;' : '')}
      height: ${theme.unit(13)};
      display: flex;
      flex-direction: column;
      padding: ${theme.unit(1)};
      text-align: left;
      cursor: pointer;
      
      @media only screen and (min-width: 800px) {
        padding-right: ${theme.unit(3)};
      }

      @media only screen and (max-width: 800px) {
        text-align: center;
        background-size: cover;
      }
      
      :before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        height: 100%;
        width: 100%;
        z-index: -99;
        background: #fff3;
        transition: all 0.4s;
      }

      :hover:before {
        background: transparent;
      }

      :after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 35%;
        background: #000a;
        z-index: -42;
        transition: all .4s;

        @media only screen and (max-width: 1050px) {
          height: 40%;
        }

        @media only screen and (max-width: 915px) {
          height: 45%;
        }
        @media only screen and (max-width: 870px) {
          height: 50%;
        }
        
        @media only screen and (max-width: 800px) {
          height: 40%;
        }

        @media only screen and (max-width: 450px) {
          height: 45%;
        }

        @media only screen and (max-width: 375px) {
          height: 55%;
        }
      }

      :hover:after {
        background: #37003787;
      }
    }
  `,
  Logo: styled.div`
    position: relative;
    top: -${theme.unit(1)};
    z-index: 2;
    width: ${theme.unit(7.5)};
    height: ${theme.unit(7)};
    background-size: contain;
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.image})`};
  `,
  ButtonExplorer: styled.a`
    background-color: ${theme.colors.YELLOW_1};
    border-radius: ${theme.unit(0.2)};
    text-transform: uppercase;
    padding: ${theme.unit(0.13)};
    padding-bottom: ${theme.unit(0.1)};
    border: none;
    outline: none;
    cursor: pointer;
  `,
  ServicesCorpContainer: styled.div`
    display: flex;
    width: 100%;
    
    @media only screen and (max-width: 995px) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: 425px) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  `,
  BoxContainer: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    height: ${theme.unit(15)};
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background-image: ${props => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: cover;
    ${props => (props.right ? 'background-position:right;' : '')}
    cursor: pointer;

    @media only screen and (max-width: 995px) {
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      height: ${theme.unit(10)};
      margin: 2% 0;
    }

    :before {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      height: 100%;
      width: 100%;
      background: #fff3;
      transition: all 0.4s;
    }

    :after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      background-color: #000a;
      opacity: 1;
      height: 50%;
      width: 100%;

      @media only screen and (max-width: 995px) {
        top: 0;
      }

      @media only screen and (max-width: 420px) {
        height: 65%;
      }
    }

    :hover {
      :before {
        background: transparent;
      }

      :nth-child(1n + 0) {
        :after {
          background-color: #37003787;
        }
      }
    }

    :nth-child(even) {
      :after {
        top: 0;
      }
      
      div{
        top: 0px;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;
      top: 50%;
      height: 50%;
      text-align: left;
      padding: ${theme.unit(0.5)};
      transition: all 0.6s;
      z-index: 1;
 
      @media only screen and (max-width: 995px) {
        text-align: center;
        top: 0;
      }

      @media only screen and (max-width: 340px) {
        word-wrap: break-word;
      }
    }
  `,
};
