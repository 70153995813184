import React, { Component } from 'react';
import { Animated as RAnimated } from 'react-animated-css';
import Waypoint from 'react-waypoint';

class Animated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: false,
    };

    this.statAnimation = this.statAnimation.bind(this);
  }

  statAnimation() {
    this.setState({ animate: true });
  }

  render() {
    const { animationIn, animationOut } = this.props;
    return (
      <div>
        <div style={{ top: 0, position: this.props.relative ? 'relative' : 'absolute' }}>
          <Waypoint onEnter={this.statAnimation} />
        </div>
        <RAnimated
          animationIn={animationIn || 'zoomIn'}
          animationOut={animationOut || 'zoomOut'}
          isVisible={this.state.animate}
        >
          {this.props.children}
        </RAnimated>
      </div>
    );
  }
}

export default Animated;
