import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Home, Contact, Visual, Corporative, Blog } from 'pages';
import { Header, ScrollBack } from 'components';
import ContentService from 'services/content';
import Loader from './loader';

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      navigate: false,
    };

    ContentService.read().then((res) => {
      localStorage.setItem('content-es', JSON.stringify(res.data));
      this.setState({ processing: false });
      setTimeout(Loader.hideLoader, 1000);
    });

    this.history = require('history').createBrowserHistory();
    this.onNavigate();
  }

  onNavigate() {
    this.history.block((location, action) => {
      if (action === 'PUSH' && this.history.location.pathname !== location.pathname) {
        Loader.showLoader();
        setTimeout(() => {
          window.scrollTo(0, 0);
          this.setState({ navigate: true });
          this.history.location.pathname = location.pathname;
          this.history.push(location.pathname);
          Loader.hideLoader();
          this.setState({ navigate: false });
        }, 350);
      }
      return this.state.navigate;
    });
  }

  render() {
    if (this.state.processing) return <div />;

    return (
      <Router history={this.history}>
        <div>
          <ScrollBack />

          {window.location.pathname.split('/').indexOf('admin') === -1 && <Header />}

          <Switch>
            <Route path="/contacto" exact component={Contact} />
            <Route path="/visual" component={Visual} />
            <Route path="/corporativo" component={Corporative} />
            <Route path="/blog" component={Blog} />
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default AppRouter;
