import theme from 'theme';
import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
  `,
  Logo: styled.div`
    height: ${theme.unit(6)};
    width: ${theme.unit(6)};
    border-bottom: ${theme.unit(0.1)} solid ${theme.colors.PURPLE_1};
    padding: ${theme.unit(1)} ${theme.unit(0.4)};
    margin: ${theme.unit(1)} auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-image: ${props => `url(${props.image})`};
  `,
  Info: styled.div`
    position: relative;
    width: 40%;
    text-align: center;
    
    ::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: ${props => `url(${props.image})`};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
      z-index: -45;
    }
    
    ::after {
      content: "";
      position: absolute;
      background-color: ${theme.colors.WHITE};
      opacity: 0.8;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -20;
    }
    
    @media only screen and (max-width: 800px) {
      display: none;
    }
  `,
};
