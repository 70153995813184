import React, { Component } from 'react';
import ImageService from 'services/images.js';
import styles from './styles';

export default class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      images: [],
      selectedImage: false,
    };

    this.getImages();
    this.uploadImage = this.uploadImage.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.fileInput = React.createRef();
  }

  getImages() {
    ImageService.getAll()
      .then((response) => {
        this.setState({ images: response.data, processing: false, selectedImage: false });
      })
      .catch(() => {
        this.setState({ processing: false });
      });
  }

  uploadImage() {
    if (!this.state.selectedImage || this.state.processing) return;

    this.setState({ processing: true });

    ImageService.upload(this.state.selectedImage)
      .then(() => {
        this.getImages();
      })
      .catch(() => {
        this.setState({ processing: false });
      });
  }

  selectImage() {
    this.setState({ selectedImage: this.fileInput.current.files[0] });
  }

  clearImage() {
    this.fileInput.current.value = '';
    this.setState({ selectedImage: false });
  }

  render() {
    if (this.state.processing) return <div>Cargando...</div>;

    return (
      <div>
        <h3>Subir Imagen</h3>
        <styles.FileInput htmlFor="image" visible={!this.state.selectedImage}>
            Selecciona una imagen
          <input
            type="file"
            name="image"
            id="image"
            ref={this.fileInput}
            accept="image/*"
            onChange={this.selectImage}
          />
        </styles.FileInput>
        <styles.FileActions visible={!!this.state.selectedImage}>
          <button type="button" onClick={this.uploadImage}>Subir</button>
          <button type="button" onClick={this.clearImage}>Cancelar</button>
        </styles.FileActions>

        <h3>Listado de Imagenes</h3>

        <styles.ImageGrid>
          {this.state.images.map((e, i) => (
            <a href={`${window.location.origin}/images/${e}`} target="_blank" rel="noopener noreferrer">
              <styles.Image key={i} src={`${window.location.origin}/images/${e}`} title={e} />
            </a>
          ))}
        </styles.ImageGrid>
      </div>
    );
  }
}
