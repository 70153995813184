import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import t from 'locale';
import styles from './styles';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subMenu: false,
      isScrolled: true,
    };

    this.menuItems = [
      { label: t('header.links.home'), route: '/' },
      {
        label: t('header.links.services'),
        route: 'service',
        routes: [
          {
            label: t('header.links.visual'),
            route: '/visual',
            image: '/assets/img/visual_dropdown.jpg',
          },
          {
            label: t('header.links.corporative'),
            route: '/corporativo',
            image: '/assets/img/trademark_development.jpg',
          },
        ],
      },
      { label: t('header.links.contact'), route: '/contacto' },
      { label: t('header.links.blog'), route: '/blog' },
    ];

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ subMenu: false, isScrolled: window.scrollY <= 0 });
  }

  toRoute(route) {
    return () => {
      this.setState({ subMenu: false });

      this.props.history.push(route);
    };
  }

  toggleSubMenu(route) {
    return () => {
      this.setState((prev) => {
        if (prev.subMenu === route) {
          return { subMenu: false };
        }
        return { subMenu: route };
      });
    };
  }

  render() {
    const currentPath = this.props.location.pathname;

    return (
      <div>
        <styles.NavBar scroll={this.state.isScrolled}>
          <styles.Logo onClick={this.toRoute('/')} />

          <styles.MenuItemsContainer>
            {this.menuItems.map((menuItem, index) => (
              <styles.MenuItem
                key={index}
                onTouchStart={(e) => { e.target.blur(); }}
                onClick={
                  menuItem.routes
                    ? this.toggleSubMenu(menuItem.route)
                    : this.toRoute(menuItem.route)
                }
                subMenuActive={menuItem.route === this.state.subMenu}
                active={
                  currentPath === menuItem.route
                  || (menuItem.routes && menuItem.routes.map(e => e.route).includes(currentPath))
                }
              >
                {menuItem.label}
              </styles.MenuItem>
            ))}
          </styles.MenuItemsContainer>
        </styles.NavBar>

        <styles.SubMenuItemsContainer visible={!!this.state.subMenu}>
          {this.menuItems
            .filter(item => !!item.routes)
            .map(menuItem => menuItem.routes.map((subItem, index) => (
              <styles.SubMenuItem
                image={subItem.image}
                key={index}
                visible={menuItem.route === this.state.subMenu}
                onClick={this.toRoute(subItem.route)}
                active={currentPath === subItem.route}
                left={index === 0}
              >
                {subItem.label}
              </styles.SubMenuItem>
            )))}
        </styles.SubMenuItemsContainer>
      </div>
    );
  }
}

export default withRouter(Header);
