import React, { Component } from 'react';
import styles from './styles';

class Input extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (this.props.onChange) this.props.onChange(e.target.value);
  }

  render() {
    return (
      <styles.Input
        icon={this.props.icon}
        iconColor={this.props.iconColor}
        styles={this.props.styles}
        error={this.props.error}
      >
        <input
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          name={this.props.name}
          onChange={this.onChange}
        />
      </styles.Input>
    );
  }
}

export default Input;
