import React, { Component } from 'react';
import { MainLayout, Text } from 'components';
import PostService from 'services/post';
import t from 'locale';
import styles from './styles';

class PostList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      processing: true,
    };

    this.getAll = this.getAll.bind(this);
  }

  componentDidMount() {
    this.getAll();
  }

  getAll() {
    PostService.getAll().then((res) => {
      this.setState({ posts: res.data, processing: false });
    });
  }

  toRoute(route) {
    return () => {
      this.props.history.push(`blog/${route}`);
    };
  }

  render() {
    // TODO: Replace with purpo loader
    if (this.state.processing) return <div />;

    return (
      <MainLayout pageTitle={t('blog.title')}>
        <styles.Container>
          {this.state.posts.map((post, i) => (
            <styles.Post
              key={i}
              onClick={this.toRoute(post.slug)}
              image={post._embedded['wp:featuredmedia']['0'].source_url}
            >
              <div>
                <Text type="BOLD" size="S" color="YELLOW_1">
                  {post.title.rendered}
                </Text>
              </div>
              <Text type="LIGHT" size="XS" color="WHITE" tag="p">
                <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
              </Text>
            </styles.Post>
          ))}
        </styles.Container>
      </MainLayout>
    );
  }
}

export default PostList;
