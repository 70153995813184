import React, { Component } from 'react';
import { Text, ContactForm, MainLayout } from 'components';
import t from 'locale';
import styles from './styles';

class Contact extends Component {
  render() {
    return (
      <MainLayout
        pageTitle={t('contact.head.title')}
        pageSubtitle={t('contact.head.description')}
      >
        <styles.Container>
          <ContactForm />

          <styles.Info image="/assets/img/contact_bg.jpg">
            <styles.Logo image="/assets/svg/purpo_vertical_purple.svg" />

            <br />

            <Text type="SEMIBOLD" size="S" color="PURPLE_1">{t('contact.info.email')}</Text>

            <br />

            <Text type="SEMIBOLD" size="S" color="PURPLE_1">{t('contact.info.phone')}</Text>
          </styles.Info>
        </styles.Container>
      </MainLayout>
    );
  }
}

export default Contact;
