import theme from 'theme';
import styled from 'styled-components';

const POST_WIDTH = 9.3;

export default {
  Container: styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;


    @media only screen and (min-width: 880px) {
      padding-top: ${theme.unit(0.5)};
      width: ${theme.unit(POST_WIDTH * 3 + 2)};
    }

    @media only screen and (max-width: 880px) {
      background-color: ${theme.colors.GRAY_2};
    }
  `,
  Post: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${theme.unit(POST_WIDTH)};
    height: ${theme.unit(12)};
    background-color: ${theme.colors.GRAY_2};
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    :hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    @media only screen and (min-width: 880px) {
      margin: ${theme.unit(0.3)};
    }

    @media only screen and (max-width: 880px) {
      width: 100%;
    }

    :before {
      content: "";
      position: absolute;
      width: 100%;
      height: 50%;
      z-index: 1;
      background: ${props => `url(${props.image})`};
      background-repeat: no-repeat;
      background-size: cover;
      transition: background-position 0.3s;
    }

    :after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      height: 50%;
      width: 100%;
      background: #fff7;
      transition: all 0.4s;
      z-index: 2;
    }

    :hover:after {
      background: transparent;
    }

    & > p {
      text-align: left;
      padding-left: ${theme.unit(0.6)};
      padding-top: ${theme.unit(1.5)};

      ::after {
        content: " Leer Mas...";
        font-family: ${theme.texts.types.BOLD};
        font-size: ${theme.texts.sizes.X};
        color: ${theme.colors.YELLOW_1};
      }
    }

    & > div {
      position: relative;
      top: 55%;
      height: 50%;
      padding: ${theme.unit(0.3)};
      padding-left: ${theme.unit(0.6)};
      padding-top: 0px;
      text-align: center;
    }
  `,
};
