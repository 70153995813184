import React, { Component } from 'react';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ContentService from 'services/content';
import styles from './styles';

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      processing: true,
    };

    this.saveContent = this.saveContent.bind(this);
  }

  componentDidMount() {
    ContentService.read().then((res) => {
      this.setState({ content: res.data, processing: false });
    });
  }

  saveContent() {
    this.setState({ processing: true });
    ContentService.save(this.state.content)
      .then(() => {
        this.setState({ processing: false });
      })
      .catch(() => {
        this.setState({ processing: false });
      });
  }

  render() {
    if (this.state.processing) return <div>Cargando</div>;

    return (
      <div>
        <styles.Button type="button" value="Guardar" onClick={this.saveContent} />

        <JsonEditor
          value={this.state.content}
          onChange={(e) => {
            this.setState({ content: e });
          }}
        />
      </div>
    );
  }
}

export default Content;
