import React, { Component } from 'react';
import PostService from 'services/post';
import { Text, Footer } from 'components';
import moment from 'moment';
import 'moment/locale/es';
import t from 'locale';
import styles from './styles';

class PostByUrl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: null,
      processing: true,
    };

    this.getByUrl = this.getByUrl.bind(this);
  }

  componentDidMount() {
    this.getByUrl();
  }

  getByUrl() {
    PostService.getByUrl(this.props.match.params.url).then((res) => {
      this.setState({ post: res.data[0], processing: false });
    })
      .catch(() => {
        this.setState({ post: null, processing: false });
      });
  }

  toRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { post } = this.state;

    // TODO: Replace with purpo loader
    if (this.state.processing) return <div />;

    // TODO: Add translation styles etc..
    if (!post) {
      this.toRoute('/blog');
      return <div />;
    }
    return (
      <div>
        <styles.Head image={post._embedded['wp:featuredmedia']['0'].source_url}>
          <Text type="BOLD" size="L" color="WHITE">{post.title.rendered}</Text>

          <Text type="SEMIBOLD" size="S" color="YELLOW_1" id="date">
            {moment(post.date).lang('es').format('LL')}
          </Text>

          <styles.Return onClick={() => this.toRoute('/blog')}>{t('post.return')}</styles.Return>
        </styles.Head>

        <styles.Container dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        <Footer />
      </div>
    );
  }
}

export default PostByUrl;
