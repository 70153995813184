import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import styles from './styles';

class Section extends Component {
  render() {
    const { style, bgImage, bgImageAlt, strength, blur } = this.props;

    return (
      <Parallax
        bgImage={bgImage}
        bgImageAlt={bgImageAlt}
        strength={strength}
        blur={blur}
      >
        <div
          style={{ ...styles.container, ...style }}
        >
          {this.props.children}
        </div>
      </Parallax>
    );
  }
}

export default Section;
