import React, { Component } from 'react';
import { MainLayout, ServicesContent } from 'components';
import t from 'locale';

class Visual extends Component {
  render() {
    return (
      <MainLayout
        pageTitle={t('visual.title')}
        footerTitle={t('visual.servicesFooterTitle')}
        footerSubtitle={t('visual.servicesFooterDescription')}
        route="/contacto"
      >
        <ServicesContent
          image="/assets/img/home_photography.jpg"
          title={t('visual.photography.title')}
          description={t('visual.photography.description')}
        />

        <ServicesContent
          image="/assets/img/home_audiovisual.jpg"
          left
          title={t('visual.production.title')}
          description={t('visual.production.description')}
        />
      </MainLayout>
    );
  }
}

export default Visual;
