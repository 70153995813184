import styled from 'styled-components';

export default {
  Input: styled.input`
    border: none;
    color: #444;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 3px;

    &[type="text"] {
      border: 1px solid #5d5db1;
      max-width: 500px;
    }

    &[type="button"] {
      background: #5d5db1;
      color: #fff;
    }
  `,
  TextArea: styled.textarea`
    border: 1px solid #5d5db1;
    color: #444;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 3px;
  `,
  Form: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    & img {
      border-style: none;
      position: absolute;
      right: 5px;
      max-height: 110px;
      max-width: 300px;
    }
  `,
};
