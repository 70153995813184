export default {
  colorPallete: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    width: 200,
    height: 50,
    textShadow: `
      2px 0 0 #000,
      -2px 0 0 #000,
      0 2px 0 #000,
      0 -2px 0 #000,
      1px 1px #000,
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000
    `,
  },
};
