import theme from 'theme';
import styled from 'styled-components';

export default {
  Container: styled.div`
    position: relative;
    box-sizing: border-box;
    width: 60%;
    padding: ${theme.unit(1)};
    text-align: center;
    
    @media only screen and (max-width: 800px) {
      width: 100%;
      max-width: 600px;
      left: 50%;
      transform: translateX(-50%);
}
    }
  `,
  Form: styled.div`
    box-sizing: border-box;
    margin-top: ${theme.unit(0.5)};
    visibility: ${props => (props.processing ? 'hidden' : 'visible')};

    @media only screen and (min-width: 800px) {
      padding: ${theme.unit(2)};
      padding-top: 0;
    }

    @media only screen and (max-width: 800px) {
      min-width: ${theme.unit(9)};
    }
  `,
  Button: styled.input`
    font-family: ${theme.texts.types.BOLD};
    font-size: ${theme.texts.sizes.S};
    width: 100%;
    height: ${theme.unit(1)};
    border: none;
    background-color: ${theme.colors.YELLOW_1};
    margin-botton: ${theme.unit(2)};
    margin-top: ${theme.unit(1)};
    color: ${theme.colors.PURPLE_1};

    ${props => props.disabled && `  
      background-color: ${theme.colors.GRAY_1};
      color: ${theme.colors.WHITE};
    `}
  `,
  InputName: `
    flex-grow: 1;
    margin-right: ${theme.unit(0.5)};
  `,
  InputsContainer: styled.div`
    display: flex;
    margin-bottom: ${theme.unit(1)};
  `,
  TextAreaContainer: styled.div`
    font-family: 'Purpo';
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: ${theme.unit(1)};


    textarea{
      font-family: ${theme.texts.types.SEMIBOLD};
      font-size: ${theme.texts.sizes.S};
      position: relative;
      top: 2px;
      background-color: ${theme.colors.WHITE};
      flex-grow: 1;
      resize: vertical;
      text-indent:${theme.unit(0.8)};
      min-height: ${theme.unit(4)};
      border: ${theme.unit(0.04)} solid ${theme.colors.GRAY_2};
      border-radius: 0;
      padding-top: ${theme.unit(0.1)};

      ::placeholder {
        text-indent:${theme.unit(0.8)};
        transition-timing-function: ease;
        transition: opacity 0.2s;
      }

      :focus {
        ::placeholder {
            opacity: 0;
        }
      }
    }

    ::before {
      font-family: "Purpo";
      font-size: ${theme.texts.sizes.S};
      content: "3";
      color: ${theme.colors.GRAY_1};
      padding: ${theme.unit(0.19)};
      position: absolute;
      z-index: 1;
    }
  `,
  ModalContent: styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Loader: styled.div`
    position: relative;
    display: ${props => (props.visible ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    height: 100%;

    ::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('/assets/img/loader-static.png');
    }

    ::after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('/assets/img/loader-movible.png');
      -webkit-animation: rotation .8s infinite linear;
    }
  `,
};
