import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import PostService from 'services/post';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styles from './styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class FormPost extends Component {
  constructor(props) {
    super(props);

    this.isCreateForm = !this.props.match.params.id;

    this.state = {
      editorState: EditorState.createEmpty(),
      htmlState: '',
      title: '',
      url: '',
      thumbnail: '',
      description: '',
      processing: !this.isCreateForm,
      notFound: false,
    };

    if (!this.isCreateForm) this.getPostById();
    this.createPost = this.createPost.bind(this);
    this.savePost = this.savePost.bind(this);
  }

  onChange(name) {
    return (e) => {
      this.setState({ [name]: e.target.value });
    };
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
      htmlState: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  }

  getPostById() {
    PostService.getById(this.props.match.params.id)
      .then((response) => {
        const post = response.data;
        const html = post.content;
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        this.setState({
          editorState,
          htmlState: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          title: post.title,
          url: post.url,
          thumbnail: post.thumbnail_url,
          description: post.description,
          processing: false,
        });
      })
      .catch(() => {
        this.setState({ processing: false, notFound: true });
      });
  }

  savePost() {
    this.setState({ processing: true });

    PostService.update(
      this.props.match.params.id,
      1,
      this.state.title,
      this.state.url,
      this.state.thumbnail,
      this.state.description,
      this.state.htmlState,
    )
      .then(() => {
        alert('Post Actualizado correctamente');
        this.setState({ processing: false });
      })
      .catch(() => {
        alert('Error al actualizar intente de nuevo');
        this.setState({ processing: false });
      });
  }

  createPost() {
    this.setState({ processing: true });

    PostService.create(
      1,
      this.state.title,
      this.state.url,
      this.state.thumbnail,
      this.state.description,
      this.state.htmlState,
    )
      .then(() => {
        alert('Post Creado correctamente');
        this.setState({ processing: false });
      })
      .catch(() => {
        alert('Error al crear intente de nuevo');
        this.setState({ processing: false });
      });
  }

  render() {
    if (this.state.processing) return <div>Cargando</div>;

    if (this.state.notFound) return <div>Post no encontrado</div>;

    return (
      <div>
        <styles.Form>
          <styles.Input
            type="text"
            placeholder="Titulo"
            onChange={this.onChange('title')}
            value={this.state.title}
          />
          <styles.Input
            type="text"
            placeholder="URL"
            onChange={this.onChange('url')}
            value={this.state.url}
          />
          <styles.Input
            type="text"
            placeholder="Thumbnail Url"
            onChange={this.onChange('thumbnail')}
            value={this.state.thumbnail}
          />
          <img src={`${window.location.origin}/images/${this.state.thumbnail}`} alt="thumbnail" />
          <styles.TextArea
            placeholder="Descripcion"
            onChange={this.onChange('description')}
            value={this.state.description}
          />
        </styles.Form>

        <Editor
          editorState={this.state.editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(e) => {
            this.onEditorStateChange(e);
          }}
          wrapperStyle={{
            border: '1px solid #5d5db1',
            margin: '5px',
            padding: '5px 10px',
            borderRadius: '3px',
          }}
        />

        <styles.Input
          type="button"
          value="Guardar"
          onClick={this.isCreateForm ? this.createPost : this.savePost}
        />
      </div>
    );
  }
}

export default FormPost;
