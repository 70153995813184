import theme from 'theme';
import styled from 'styled-components';

export default {
  ServicesContainer: styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    z-index: -10;

    ::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      overflow: hidden;
      background-image: ${props => `url(${props.image})`};
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -5;
      ${props => (props.left ? 'left:50%;' : 'left:0px;')}

      @media only screen and (max-width: 800px) {
        background-size: cover;
        left: 0px;
        width: 100%;
      }
    }
    
    @media only screen and (min-width: 800px) {
      background-color: ${theme.colors.WHITE};
      ${props => (props.left
    ? `
          padding-right: 50%; 
          text-align: right;
        `
    : `
          padding-left: 50%; 
          text-align: left;
        `)}  
    }
    
    & > div {
      display: flex;
      flex-direction: column;
      padding-top: ${theme.unit(1)};
      padding-bottom: ${theme.unit(2)};
      ${props => (props.left ? `padding-right: ${theme.unit(1)};` : `padding-left: ${theme.unit(1)};`)}

      @media only screen and (max-width: 800px) {
        position: relative;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin: ${theme.unit(0.5)};
        padding-right: 0px;
        padding-left: 0px;

        :before {
          content: "";
          position: absolute;
          top: 0px;
          background-color: ${theme.colors.WHITE};
          width: 100%;
          height: 100%;
          opacity: 0.9;
          z-index: -2;
        }
      }

      & > span {
        position: relative;
        z-index: -1;

        @media only screen and (max-width: 800px) {
          text-align: center;
          display: flex;
          padding: ${theme.unit(1)};
          padding-bottom: 0;
          box-sizing: border-box;
        }
      }

      & > p {
        position: relative;
        box-sizing: border-box;
        padding-top: ${theme.unit(0.4)};
        text-align: justify;
        word-wrap: break-word;
        z-index: -1;

        @media only screen and (min-width: 800px) {
          padding-top: ${theme.unit(0.7)};
          width: 50%;
          min-width: 260px;

          ${props => (props.left
    ? `
              align-self: flex-end;
              padding-right: ${theme.unit(0.7)};   
              `
    : `
              padding-left: ${theme.unit(0.7)}; 
              `)}
        }
        
        @media only screen and (max-width: 800px) {
          box-sizing: border-box;
          padding-top: ${theme.unit(0.8)};
          margin-bottom: ${theme.unit(2)};
          width: 80%;
        }

        :before {
          content: "";
          position: absolute;
          top: 0px;
          height: 100%;

          @media only screen and (min-width: 800px) {
            width: ${theme.unit(4)};
            ${props => (props.left
    ? `
                border-top: 10px solid ${theme.colors.YELLOW_1};
                border-right: 10px solid ${theme.colors.YELLOW_1};
                right: 0px;
              `
    : `
                left: 0px;
                border-top: 10px solid ${theme.colors.YELLOW_1};
                border-left: 10px solid ${theme.colors.YELLOW_1};
              `)}
          }
          @media only screen and (max-width: 800px) {
            width: 100%;
            border-top: 10px solid ${theme.colors.YELLOW_1};            
          }
        }
      }
    `,
};
