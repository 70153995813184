import theme from 'theme';
import styled from 'styled-components';

export default {
  PanelLayout: styled.div`
    display: flex;
    position: relative;

    nav {
      position: fixed;
      background: #aac;
      width: ${theme.unit(5)};
      min-height: 100%;
      button {
        cursor: pointer;
        width: 100%;
        margin: 0px;
        transition: all 0.5s;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        background: none;
        border: none;
        text-align: left;

        :hover {
          padding-left: 50px;
          color: #eee;
          background: #333;
        }
      }
    }

    & > div {
      margin-left: ${theme.unit(5)};
      flex: 1;
      padding: ${theme.unit(1)};
    }
  `,
};
