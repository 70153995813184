import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'jsoneditor-react/es/editor.min.css';
import ListPost from './list';
import FormPost from './create';

class Blog extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/admin/blog" component={ListPost} />
          <Route exact path="/admin/blog/create" component={FormPost} />
          <Route exact path="/admin/blog/update/:id" component={FormPost} />
        </Switch>
      </div>
    );
  }
}

export default Blog;
