import axios from 'axios';

export default {
  upload(image) {
    const url = `${window.location.origin}/image.php`;
    const data = new FormData();

    data.append('image', image);

    return axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  getAll() {
    const url = `${window.location.origin}/image.php`;

    return axios.get(url);
  },
};
