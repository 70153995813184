import theme from 'theme';
import styled from 'styled-components';

export default {
  Img: styled.img`
    height: ${theme.unit(1)};
  `,
  Button: styled.button`
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: fixed;
    top: 90%;
    left: 96.5%;
    z-index: 99;
    padding: 0;
    margin:0;
  `,
};
