export default {
  WHITE: '#FFF',
  BLACK: '#000',
  GRAY_1: '#ccc',
  GRAY_2: '#333',
  GRAY_3: '#1A1A1A',
  PURPLE_1: '#660066',
  PURPLE_2: '#993399',
  YELLOW_1: '#FFBD5B',
};
