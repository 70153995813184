class Loader {
  startLoad(onLoad) {
    this.filesToLoad = [
      '/assets/img/loader-movible.png',
      '/assets/img/loader-static.png',
      '/assets/img/scroll_up.png',
      '/assets/img/icon.ico',
      '/assets/img/visual_dropdown.jpg',
      '/assets/img/home_trademark_development.jpg',
      '/assets/img/home_audiovisual.jpg',
      '/assets/img/home_photography.jpg',
      '/assets/img/contact_bg.jpg',
      '/assets/img/home_digital_marketing.jpg',
      '/assets/img/home_parallax.jpg',
      '/assets/img/trademark_development.jpg',
      '/assets/img/home_graphic_design.jpg',
      '/assets/img/home_landing.jpg',
      '/assets/img/header.jpg',
      '/assets/img/home_web_development.jpg',
      '/assets/svg/purpo_vertical_white.svg',
      '/assets/svg/purpo_vertical_purple.svg',
    ];

    this.filesLoaded = 0;

    this.filesToLoad.forEach((e) => {
      const file = new Image();
      file.src = e;
      file.onload = () => {
        this.filesLoaded += 1;
        if (this.filesLoaded === this.filesToLoad.length && onLoad) {
          onLoad.apply();
        }
      };
    });
  }

  hideLoader() {
    document.getElementById('loader-left').style.transition = 'left 0.3s .3s, visibility 0s 1.6s';
    document.getElementById('loader-left').style.left = '-51%';
    document.getElementById('loader-left').style.visibility = 'hidden';

    document.getElementById('loader-right').style.transition = 'left 0.3s .3s, visibility 0s 1.6s';
    document.getElementById('loader-right').style.left = '100%';
    document.getElementById('loader-right').style.visibility = 'hidden';
  }

  showLoader() {
    document.getElementById('loader-left').style.transition = 'left 0.3s, visibility 0s';
    document.getElementById('loader-left').style.visibility = 'visible';
    document.getElementById('loader-left').style.left = '0px';

    document.getElementById('loader-right').style.transition = 'left 0.3s, visibility 0s';
    document.getElementById('loader-right').style.visibility = 'visible';
    document.getElementById('loader-right').style.left = '50%';
  }
}

export default new Loader();
