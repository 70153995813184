import React, { Component } from 'react';
import { Text, Animated, Footer } from 'components';
import { Parallax } from 'react-parallax';
import 'assets/carousel.css';
import { Carousel } from 'react-responsive-carousel';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop';
import { withRouter } from 'react-router-dom';
import t from 'locale';
import styles from './styles';

class Home extends Component {
  toRoute(route) {
    return () => {
      this.props.history.push(route);
    };
  }

  render() {
    return (
      <div>
        <Parallax
          bgImage="/assets/img/home_landing.jpg"
          bgImageAlt="your design here"
          strength={200}
        >
          <styles.DivFullScreen opacityWhite column flexEnd>
            <styles.Logo image="/assets/svg/purpo_vertical_purple.svg" />

            <Text type="LIGHT" size="S" color="PURPLE_1">{t('home.purpo.title')}</Text>

            <br />

            <styles.ServicesLoop>
              <TypistLoop>
                {t('home.purpo.services').map((text, key) => (
                  <Typist startDelay={0} key={key} delayGenerator={() => 30}>

                    <Text type="BOLD" size="M" color="PURPLE_1">{text}</Text>

                    <Typist.Backspace count={text.length} delay={1000} />
                  </Typist>
                ))}
              </TypistLoop>
            </styles.ServicesLoop>

            <br />

            <div>
              <Text type="SEMIBOLD" size="S" color="PURPLE_1">{t('home.purpo.subTitle1')}</Text>

              <Text type="BLACK" size="S" color="PURPLE_1">{t('home.purpo.black')}</Text>

              <Text type="SEMIBOLD" size="S" color="PURPLE_1">{t('home.purpo.subTitle2')}</Text>

              <br />

              <Text type="SEMIBOLD" size="S" color="PURPLE_1">{t('home.purpo.subTitle3')}</Text>
            </div>
          </styles.DivFullScreen>
        </Parallax>

        <Parallax
          bgImage="/assets/img/home_parallax.jpg"
          bgImageAlt="your design here"
          strength={500}
        >
          <styles.DivFullScreen opacityPurple>
            <styles.AboutContainer>
              <Animated>
                <Text type="BOLD" size="L" color="YELLOW_1">{t('home.about.title')}</Text>

                <br />

                <Text type="LIGHT" size="S" tag="p" color="WHITE">{t('home.about.description')}</Text>

                <br />
              </Animated>
            </styles.AboutContainer>
            <styles.CarouselContainer>
              <Animated animationIn="slideInRight">
                <styles.CarouselTitle>
                  <Text type="SEMIBOLD" size="L" color="WHITE">{t('home.carousel.title')}</Text>
                </styles.CarouselTitle>

                <styles.CarouselTopMargin />

                <Carousel
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                  emulateTouch
                >
                  <div>
                    <img alt="placeholder" src="https://via.placeholder.com/400x200" />

                    <styles.CarouselDescription>
                      <Text type="LIGHT" size="XS" color="WHITE" tag="p">{t('home.carousel.descriptions.1')}</Text>

                      <br />
                    </styles.CarouselDescription>
                  </div>

                  <div>
                    <img alt="placeholder" src="https://via.placeholder.com/400x200" />

                    <styles.CarouselDescription>
                      <Text type="LIGHT" size="XS" color="WHITE" tag="p">{t('home.carousel.descriptions.2')}</Text>

                      <br />
                    </styles.CarouselDescription>
                  </div>
                </Carousel>
              </Animated>
            </styles.CarouselContainer>
          </styles.DivFullScreen>
        </Parallax>

        <div>
          <styles.ServicesTitle>
            <Text type="BOLD" size="XL" color="YELLOW_1">{t('home.services.title')}</Text>
          </styles.ServicesTitle>

          <styles.ServiceTitle>
            <Animated relative>

              <Text type="BOLD" size="L" color="GRAY_2">{t('home.services.visual.title')}</Text>

            </Animated>
          </styles.ServiceTitle>

          <styles.ServicesContainer>
            <styles.Service image="/assets/img/home_photography.jpg" onClick={this.toRoute('/visual')}>
              <Animated animationIn="slideInLeft">
                <Text type="BOLD" size="M" color="YELLOW_1">{t('home.services.visual.photography.title')}</Text>

                <br />

                <Text type="LIGHT" size="S" color="WHITE">{t('home.services.visual.photography.description')}</Text>
              </Animated>
            </styles.Service>

            <styles.Service right image="/assets/img/home_audiovisual.jpg" onClick={this.toRoute('/visual')}>
              <Animated animationIn="slideInRight">
                <Text type="BOLD" size="M" color="YELLOW_1">{t('home.services.visual.production.title')}</Text>

                <br />

                <Text type="LIGHT" size="S" color="WHITE">{t('home.services.visual.production.description')}</Text>
              </Animated>
            </styles.Service>
          </styles.ServicesContainer>
        </div>

        <div>

          <styles.ServiceTitle>
            <Animated relative>
              <Text type="BOLD" size="L" color="GRAY_2">{t('home.services.corporative.title')}</Text>
            </Animated>
          </styles.ServiceTitle>

          <styles.ServicesCorpContainer>
            <styles.BoxContainer image="/assets/img/home_web_development.jpg" onClick={this.toRoute('/corporativo')}>
              <Animated relative>
                <Text type="BOLD" size="M" color="YELLOW_1">{t('home.services.corporative.web.title')}</Text>

                <Text type="LIGHT" size="S" color="WHITE" tag="p">{t('home.services.corporative.web.description')}</Text>
              </Animated>
            </styles.BoxContainer>

            <styles.BoxContainer image="/assets/img/home_trademark_development.jpg" onClick={this.toRoute('/corporativo')}>
              <Animated relative>
                <Text type="BOLD" size="M" color="YELLOW_1">{t('home.services.corporative.trademark.title')}</Text>

                <Text type="LIGHT" size="S" color="WHITE" tag="p">{t('home.services.corporative.trademark.description')}</Text>
              </Animated>
            </styles.BoxContainer>

            <styles.BoxContainer image="/assets/img/home_digital_marketing.jpg" onClick={this.toRoute('/corporativo')}>
              <Animated relative>
                <Text type="BOLD" size="M" color="YELLOW_1">{t('home.services.corporative.marketing.title')}</Text>

                <Text type="LIGHT" size="S" color="WHITE" tag="p">{t('home.services.corporative.marketing.description')}</Text>
              </Animated>
            </styles.BoxContainer>

            <styles.BoxContainer image="/assets/img/home_graphic_design.jpg" onClick={this.toRoute('/corporativo')}>
              <Animated relative>
                <Text type="BOLD" size="M" color="YELLOW_1">{t('home.services.corporative.design.title')}</Text>

                <Text type="LIGHT" size="S" color="WHITE" tag="p">{t('home.services.corporative.design.description')}</Text>
              </Animated>
            </styles.BoxContainer>

          </styles.ServicesCorpContainer>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(Home);
