import styled from 'styled-components';

export default {
  LoginLayout: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    position: relative;
    left: 50%;
    background: #aac;
    width: 600px;
    transform: translateX(-50%);

    input {
      border: none;
      color: #444;
      margin: 5px;
      padding: 5px 10px;
      border-radius: 3px;
    }

    input[type="button"] {
      background: #5d5db1;
      color: #fff;
    }
  `,
};
