import axios from 'axios';

export default (email, password) => {
  const url = `${process.env.REACT_APP_API_HOST}/user/login`;
  const data = {
    email,
    password,
  };

  return axios.post(url, data);
};
