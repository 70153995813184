import styled from 'styled-components';

export default {
  Button: styled.input`
    border: none;
    color: #444;
    margin: 5px;
    margin-left: 0px;
    padding: 5px 10px;
    border-radius: 3px;

    &[type="button"] {
      background: #5d5db1;
      color: #fff;
    }
  `,
};
