import theme from 'theme';
import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
    padding: ${theme.unit(1.5)};
    background-color: ${theme.colors.BLACK};
    box-sizing: border-box;
    justify-content: space-around;
    align-items: left;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }
  `,
  Us: styled.div`
    width: 20%;
    text-align: center;
    max-width: ${theme.unit(7.2)};
    display: flex;
    flex-direction: column;

    :before {
      content: " ";
      order: -1;
      height: ${theme.unit(3.5)};
      background-image: ${props => `url(${props.image})`};
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: ${theme.unit(0.3)};
    }

    :after {
      content: " ";
      order: -1;
      border-bottom: ${theme.unit(0.05)} solid ${theme.colors.GRAY_1};
      margin-bottom: ${theme.unit(0.5)};
    }

    @media (max-width: 800px) {
      width: 100%;
    }
  `,
  RowSections: styled.div`
    display: flex;
    width: 40%;
    justify-content: space-around;
    @media (max-width: 800px) {
      width: 100%;
    }
  `,
  Section: styled.div`
    top: ${theme.unit(1.5)};
    width: 20%;
    max-width: ${theme.unit(4)};
    display: flex;
    flex-direction: column;

    & > * {
      margin-top: ${theme.unit(0.3)};
    }

    @media (max-width: 800px) {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: ${theme.unit(1)};
    }
  `,
  Icon: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${theme.unit(0.8)};

    :before {
      position: absolute;
      font-size: ${theme.texts.sizes.XS};
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-family: "Purpo";
      content: "${props => props.icon}";
      color: ${theme.colors.GRAY_1};
    }

    @media (max-width: 800px) {
      :before{
        content: "";
      }
      padding:0;
    }
  `,
  Link: styled.a`
    text-decoration: none;
    color: ${theme.colors.GRAY_1};
    font-size: ${theme.texts.sizes.XS};
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s linear 0s;

    :hover {
      color: ${theme.colors.YELLOW_1};
      padding-left: ${theme.unit(0.3)};
    }
  `,
  Info: styled.div`
    background: ${theme.colors.GRAY_2};
    text-align: center;
    padding: ${theme.unit(1)};
  `,
};
