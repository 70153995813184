import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Animated, Text, Footer } from 'components';
import styles from './styles';

class MainLayout extends Component {
  toRoute(route) {
    this.props.history.push(route);
  }

  render() {
    return (
      <div>
        <styles.Head image="/assets/img/header.jpg">
          <Text type="BOLD" size="L" color="WHITE">{this.props.pageTitle}</Text>

          <br />

          <Text type="SEMIBOLD" size="M" color="WHITE">{this.props.pageSubtitle}</Text>
        </styles.Head>

        <div>
          {this.props.children}
        </div>

        { (this.props.footerTitle || this.props.footerSubtitle)
          && (
            <styles.ServicesFooterContainer onClick={() => this.toRoute(`${this.props.route}`)}>
              <Animated relative>
                <Text type="SEMIBOLD" size="S" color="WHITE">{this.props.footerSubtitle}</Text>

                <Text type="BOLD" size="L" color="YELLOW_1">{this.props.footerTitle}</Text>
              </Animated>
            </styles.ServicesFooterContainer>
          )
        }

        <Footer />
      </div>
    );
  }
}

export default withRouter(MainLayout);
