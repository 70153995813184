import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import 'typeface-roboto';
import AppRouter from './AppRouter';
import 'normalize.css';
import './style.css';
import Loader from './loader';

Loader.startLoad(() => {
  ReactDOM.render(<AppRouter />, document.querySelector('#root'));
});

function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

function reloadApp() {
  ReactDOM.unmountComponentAtNode(document.querySelector('#root'));
  // eslint-disable-next-line
  window.location.href = window.location.href;
}

if (!isMobileDevice()) {
  let isResizing = null;

  window.addEventListener('resize', () => {
    Loader.showLoader();

    if (isResizing !== null) {
      clearTimeout(isResizing);
    }

    isResizing = setTimeout(reloadApp, 1000);
  });
}

window.addEventListener('orientationchange', () => {
  reloadApp();
}, false);

registerServiceWorker();
