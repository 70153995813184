import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PostList from './post-list';
import PostByUrl from './post-by-url';

class Blog extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/blog" component={PostList} />
        <Route path="/blog/:url" component={PostByUrl} />
      </Switch>
    );
  }
}

export default Blog;
