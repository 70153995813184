import React, { PureComponent } from 'react';
import styles from './styles';

class Text extends PureComponent {
  constructor(props) {
    super(props);

    this.styledTag = (this.props.tag === 'p') ? styles.P : styles.Span;
  }

  render() {
    return <this.styledTag {...this.props}>{this.props.children}</this.styledTag>;
  }
}

export default Text;
