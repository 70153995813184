import React, { Component } from 'react';
import theme from 'theme';
import { Section, Animated, Text } from 'components';
import styles from './styles';

class ThemeeViewer extends Component {
  colorPallete(color, name, i) {
    return (
      <div key={i} style={{ ...styles.colorPallete, backgroundColor: color }}>
        {name}
      </div>
    );
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        {Object.keys(theme.colors).map((e, i) => this.colorPallete(theme.colors[e], e, i))}

        <Text type="BOLD" size="XS" color="PURPLE_1" tag="p">Texto</Text>
        <Text type="LIGHT" size="S" color="PURPLE_2" tag="p">Texto</Text>
        <Text type="SEMIBOLD" size="M" color="PURPLE_3" tag="p">Texto</Text>
        <Text type="BOLD" size="L" color="PURPLE_4" tag="p">Texto</Text>
        <Text type="BLACK" size="XL" color="YELLOW_1" tag="p">Texto</Text>

        <Section>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Animated animationIn="bounceIn">
              <div style={{ backgroundColor: '#222', width: 200, height: 300, margin: 50 }} />
            </Animated>
            <Animated animationIn="rotateIn">
              <div style={{ backgroundColor: '#222', width: 200, height: 300, margin: 50 }} />
            </Animated>
            <Animated>
              <div style={{ backgroundColor: '#222', width: 200, height: 300, margin: 50 }} />
            </Animated>
            <Animated>
              <div style={{ backgroundColor: '#222', width: 200, height: 300, margin: 50 }} />
            </Animated>
          </div>
        </Section>
      </div>
    );
  }
}

export default ThemeeViewer;
