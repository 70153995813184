import React, { Component } from 'react';
import { Text } from 'components';
import styles from './styles';

class ServicesContent extends Component {
  render() {
    return (
      <styles.ServicesContainer image={this.props.image} left={this.props.left}>
        <div>
          <Text type="BOLD" size="L" color="GRAY_2">{this.props.title}</Text>

          <Text type="LIGHT" size="XS" color="BLACK" tag="p">{this.props.description}</Text>
        </div>
      </styles.ServicesContainer>
    );
  }
}

export default ServicesContent;
