import React, { Component } from 'react';
import LoginService from 'services/login';
import styles from './styles';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      processing: false,
    };

    this.onLogin = this.onLogin.bind(this);
  }

  onLogin() {
    if (this.state.processing) return;
    this.setState({ processing: true });

    LoginService(this.state.email, this.state.password)
      .then((r) => {
        localStorage.setItem('purpo-token', r.data.token);
        this.props.onLogin();
      })
      .catch(() => {
        localStorage.removeItem('purpo-token');
        this.props.onLogin();
      });
  }

  render() {
    return (
      <styles.LoginLayout>
        <input
          type="email"
          placeholder="email"
          onChange={(e) => {
            this.setState({ email: e.target.value });
          }}
          value={this.state.email}
        />

        <input
          type="password"
          placeholder="password"
          onChange={(e) => {
            this.setState({ password: e.target.value });
          }}
          value={this.state.password}
        />

        <input type="button" value="Login" onClick={this.onLogin} />

      </styles.LoginLayout>
    );
  }
}

export default Login;
