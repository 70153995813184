import theme from 'theme';
import styled from 'styled-components';

export default {
  Head: styled.div`
    height: 100vh;
    width: 100vw;

    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: ${props => `url(${props.image})`};
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;

    #date { 
      position: absolute;
      bottom: ${theme.unit(2)};
    }

    ::before{
      content: "";
      width: inherit;
      height: inherit;
      background-color: black;
      position: absolute;
      opacity: 0.5;
    }

    & > span {
      text-align: center;
      padding-left: ${theme.unit(0.5)};
      padding-right: ${theme.unit(0.5)};
      z-index: 2;
    }
  `,
  Return: styled.a`
    @media only screen and (max-width: 800px) {
      display: none;
    }

    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: none;
    color: ${theme.colors.BLACK};
    font-size: ${theme.texts.sizes.S};
    background: ${theme.colors.YELLOW_1};
    padding: ${theme.unit(0.2)};
    margin-bottom: ${theme.unit(0.5)};
    margin-right: ${theme.unit(0.8)};
    border-radius: ${theme.unit(0.3)};
    z-index: 2;
  `,
  Container: styled.div`
    margin: ${theme.unit(0.5)};
    padding-left: ${theme.unit(0.5)};
    border-left: ${theme.unit(0.1)} solid ${theme.colors.YELLOW_1};

    .wp-block-image.is-resized {
      overflow: hidden;
    }

    @media only screen and (min-width: 800px) {
      margin: ${theme.unit(2)};
      padding-left: ${theme.unit(1.5)};
      max-width: 60%;
      position: relative;
      left: 50%;
      transform: translateX(-60%);
    }
  `,
};
