import theme from 'theme';
import styled from 'styled-components';

export default {
  Head: styled.div`
    margin-top: ${theme.unit(2.4)};
    min-height: ${theme.unit(2.4)};
    text-align: center;
    padding-bottom: ${theme.unit(1)};
    
    @media only screen and (max-width: 400px) {
      padding-bottom: ${theme.unit(0.5)};
    }
    
    ::before {
      content: "";
      position: absolute;
      background-image: ${props => `url(${props.image})`};
      background-repeat: no-repeat;
      background-size: cover;
      height: ${theme.unit(5.8)};
      width: 100%;
      top: 0;
      left: 0;
      z-index: -45;
    }
    
    ::after {
      content: "";
      position: absolute;
      background-color: ${theme.colors.PURPLE_1};
      opacity: 0.7;
      height: ${theme.unit(3.4)};
      width: 100%;
      top: ${theme.unit(2.4)};
      left: 0;
      z-index: -20;
    }
    `,
  ServicesFooterContainer: styled.div`
  background: ${theme.colors.GRAY_2};
  cursor: pointer;

  .animated {
    display:flex;
    flex-direction: column;
    text-align: center;
  }

  span {
    :nth-child(1) {
      padding-bottom: ${theme.unit(0.5)};
      padding-top: ${theme.unit(3)};     
    }

    :nth-child(2) {
      margin-bottom: ${theme.unit(3)};    
    }
  }
  `,
};
