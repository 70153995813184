export default (k) => {
  const es = JSON.parse(localStorage.getItem('content-es'));
  const arr = k.split('.');

  let result = es;

  try {
    arr.forEach((e) => {
      result = result[e];
    });
  } catch (error) {
    return '';
  }
  return result;
};
