import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { withRouter } from 'react-router-dom';
import styles from './styles';

class ScrollBack extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <styles.Button type="button">
        <ScrollToTop showUnder={160}>
          <styles.Img src="/assets/img/scroll_up.png" alt="scroll-to-top" />
        </ScrollToTop>
      </styles.Button>
    );
  }
}

export default withRouter(ScrollBack);
