import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import styles from './styles';
import Login from './login';
import Content from './content';
import Blog from './blog';
import Images from './image';

export default class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecure: false,
    };

    this.onLogin = this.onLogin.bind(this);
    this.onLogOut = this.onLogOut.bind(this);
  }

  componentDidMount() {
    this.onLogin();
  }

  onLogin() {
    if (localStorage.getItem('purpo-token')) {
      this.setState({ isSecure: true });
    }
  }

  onLogOut() {
    localStorage.removeItem('purpo-token');
    this.setState({ isSecure: false });
  }

  toRoute(route) {
    return () => {
      this.props.history.push(route);
    };
  }

  render() {
    if (!this.state.isSecure) return <Login onLogin={this.onLogin} />;

    return (
      <styles.PanelLayout>
        <nav>
          <button type="button" onClick={this.toRoute('/admin')}>
            Contenido
          </button>
          <button type="button" onClick={this.toRoute('/admin/blog')}>
            Blog
          </button>
          <button type="button" onClick={this.toRoute('/admin/images')}>
            Imagenes
          </button>
          <button type="button" onClick={this.onLogOut}>
            Salir
          </button>
        </nav>
        <Switch>
          <Route exact path="/admin" component={Content} />
          <Route path="/admin/blog" component={Blog} />
          <Route path="/admin/images" component={Images} />
        </Switch>
      </styles.PanelLayout>
    );
  }
}
