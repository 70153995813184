import theme from 'theme';
import styled from 'styled-components';

const headerHeight = theme.unit(2.4);

export default {
  NavBar: styled.nav`
    position: ${props => (props.scroll ? 'absolute' : 'fixed')};
    top: 0;
    left: 0;
    width: 100%;
    height: ${headerHeight};
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    
    ::before {
      content: "";
      position: ${props => (props.scroll ? 'absolute' : 'fixed')};
      width: 100vw;
      height: ${headerHeight};
      transition: background-color .6s;
      background-color: ${props => (props.scroll ? 'rgba(102, 0, 102, .7)' : 'rgba(102, 0, 102, 1)')};
    }
    `,
  Logo: styled.a`
    margin-left: ${theme.unit(2)};
    cursor: pointer;
    z-index: 1;

    @media only screen and (max-width: 800px) {
      margin-left: ${theme.unit(0.5)};
    }
    
    ::before {
      font-family: "Purpo";
      font-size: ${theme.unit(1.5)};
      color: ${theme.colors.WHITE};
      content: "1";
      margin-bottom: ${theme.unit(2)};

      @media only screen and (max-width: 800px) {
        content: "2";
        font-size: ${theme.unit(1.7)};
      }

      @media only screen and (max-width: 420px) {
        display: none;
      }
    }
  `,
  MenuItemsContainer: styled.div`
    margin-right: ${theme.unit(2)};

    @media only screen and (max-width: 500px) {
      margin-right: 0px;
      width: 100%;
    }
  `,
  MenuItem: styled.a`
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: ${theme.unit(0.5)};
    color: ${theme.colors.WHITE};
    border: none;
    text-transform: uppercase;
    font-family: ${theme.texts.types.SEMIBOLD};
    font-size: ${theme.texts.sizes.XS};
    outline: none;
    @media only screen and (max-width: 420px) {
      :nth-child(1) {
        margin-left: ${theme.unit(1)};
      }
    }
    
    @media only screen and (max-width: 375px) {
      margin: ${theme.unit(0.4)};
      :nth-child(1) {
        margin-left: ${theme.unit(0.7)};
      }
    }
    
    @media only screen and (max-width: 320px) {
      margin: ${theme.unit(0.4)};
      :nth-child(1) {
        margin-left: ${theme.unit(0.1)};
      }
    }

    :before {
      content: "";
      position: absolute;
      top: -${theme.unit(0.19)};
      left: 0px;
      width: 0px;
      height: ${theme.unit(0.06)};
      transition: 0.1s all;
      transition-timing-function: linear;
      background-color: ${theme.colors.YELLOW_1};
    }
    ${props => (props.subMenuActive
    ? `
        :after {
          content: "";
          position: absolute;
          bottom: -${theme.unit(0.19)};
          left: 0px;
          width: 0px;
          height: ${theme.unit(0.06)};
          transition: 0.1s all;
          transition-timing-function: linear;
          background-color: ${theme.colors.YELLOW_1};
          width: 100%;
        }
      `
    : '')}

    ${props => (props.active
    ? `
        color: ${theme.colors.YELLOW_1};
        :before {
          width: ${theme.unit(0.3)};
        }
      `
    : `
        :hover {
          color: ${theme.colors.YELLOW_1};          
          :before {
            width: ${theme.unit(0.3)};
          }
        }
      `)}
  `,
  SubMenuItemsContainer: styled.div`
    height: ${theme.unit(3.4)};
    max-height: ${theme.unit(3.4)};
    min-height: ${theme.unit(3.4)};

    ${props => (props.visible ? `
      visibility: visible;
      transition: visibility 0s;
    ` : `
      visibility: hidden;
      transition: visibility 0s 0.7s;
    `)}

    top: ${headerHeight};
    display: flex;
    position: fixed;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 871;
  `,
  SubMenuItem: styled.a`
    width: 50%;
    cursor: pointer;
    position: absolute;
    height: inherit;
    transition: bottom .6s;
    bottom: ${props => (props.visible ? '0px' : '100%')};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => (props.active ? theme.colors.YELLOW_1 : theme.colors.WHITE)};
    font-size: ${theme.texts.sizes.M};
    background-color: #0000008a;

    ${props => (props.left
    ? `
          left: 0;
          
        `
    : `
          right: 0;
        `)};

    :before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: ${props => `url(${props.image})`};
      background-repeat: no-repeat;
      background-size: cover;
      transition: background-position 0.3s;
    }

    :hover:before {
      transition: background-position 0.5s;
      background-position-y: -170px;
      @media (max-width: 500px) {
        background-position-y: -20px;
      }

      @media (max-width: 350px) {
        background-position-y: -10px;
      }
    }
  `,
};
