import axios from 'axios';
import secureConfig from './secure-config';

export default {
  create(autor, title, postUrl, thumbnailUrl, description, content) {
    const url = `${process.env.REACT_APP_API_HOST}/post`;
    const data = {
      autor,
      title,
      url: postUrl,
      thumbnail_url: thumbnailUrl,
      description,
      content,
    };

    return axios.post(url, data, secureConfig());
  },
  update(id, autor, title, postUrl, thumbnailUrl, description, content) {
    const url = `${process.env.REACT_APP_API_HOST}/post/${id}`;
    const data = {
      autor,
      title,
      url: postUrl,
      thumbnail_url: thumbnailUrl,
      description,
      content,
    };

    return axios.put(url, data, secureConfig());
  },
  getAll() {
    const url = `${process.env.REACT_APP_API_HOST}/posts?_embed`;

    return axios.get(url);
  },
  getByUrl(postUrl) {
    const url = `${process.env.REACT_APP_API_HOST}/posts?_embed&slug=${postUrl}`;

    return axios.get(url);
  },
  getById(id) {
    const url = `${process.env.REACT_APP_API_HOST}/post/${id}`;

    return axios.get(url, secureConfig());
  },
  delete(id) {
    const url = `${process.env.REACT_APP_API_HOST}/post/${id}`;

    return axios.delete(url, secureConfig());
  },
};
