import React, { Component } from 'react';
import { MainLayout, ServicesContent } from 'components';
import t from 'locale';

class Corporative extends Component {
  render() {
    return (
      <MainLayout
        pageTitle={t('corporative.title')}
        footerTitle={t('corporative.servicesFooterTitle')}
        footerSubtitle={t('corporative.servicesFooterDescription')}
        route="/contacto"
      >
        <ServicesContent
          image="/assets/img/home_graphic_design.jpg"
          title={t('corporative.design.title')}
          description={t('corporative.design.description')}
        />

        <ServicesContent
          image="/assets/img/home_trademark_development.jpg"
          left
          title={t('corporative.trademark.title')}
          description={t('corporative.trademark.description')}
        />
        <ServicesContent
          image="/assets/img/home_digital_marketing.jpg"
          title={t('corporative.marketing.title')}
          description={t('corporative.marketing.description')}
        />

        <ServicesContent
          image="/assets/img/home_web_development.jpg"
          left
          title={t('corporative.development.title')}
          description={t('corporative.development.description')}
        />
      </MainLayout>
    );
  }
}

export default Corporative;
