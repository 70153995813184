import unit from './unit';

export default {
  sizes: {
    XS: unit(0.45),
    S: unit(0.6),
    M: unit(0.8),
    L: unit(1.2),
    XL: unit(1.5),
  },
  types: {
    LIGHT: 'Titillium Light',
    SEMIBOLD: 'Titillium Semibold',
    BOLD: 'Titillium Bold',
    BLACK: 'Titillium Black',
  },
};
