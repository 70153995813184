import theme from 'theme';
import styled from 'styled-components';

const { texts } = theme;

export default {
  Span: styled.span`
    font-family: ${props => texts.types[props.type]};
    font-size: ${props => texts.sizes[props.size]};
    color: ${props => theme.colors[props.color]};
  `,
  P: styled.p`
    font-family: ${props => texts.types[props.type]};
    font-size: ${props => texts.sizes[props.size]};
    color: ${props => theme.colors[props.color]};
  `,
};
