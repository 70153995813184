import styled from 'styled-components';

export default {
  Button: styled.input`
    border: none;
    color: #444;
    padding: 5px 10px;
    border-radius: 3px;

    &[type="button"] {
      background: #5d5db1;
      color: #fff;
    }
  `,
  Table: styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    tbody tr {
      background: #fff;
      :nth-child(odd) {
        background: #f9f9f9;
      }
    }
    .delete,
    .show {
      background: #eee;
      border: none;
      color: #444;
      margin: 5px;
      padding: 5px 10px;
      border-radius: 3px;
      text-decoration: none;
      cursor: pointer;
    }

    [type="button"].delete {
      background: #ff4f4f;
      color: #fff;
    }
  `,
};
