import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Text } from 'components';
import t from 'locale';
import styles from './styles';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.linksRoutes = [
      { label: t('header.links.home'), route: '/' },
      { label: t('header.links.services'), route: '/servicios' },
      { label: t('header.links.contact'), route: '/contacto' },
      { label: t('header.links.blog'), route: '/blog' },
    ];
  }

  toRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const currentPath = this.props.location.pathname;

    return (
      <div>
        <styles.Container>
          <styles.Us image="/assets/svg/purpo_vertical_white.svg">
            <Text type="LIGHT" size="XS" color="GRAY_1">
              {t('footer.about.description')}
            </Text>
          </styles.Us>

          <styles.RowSections>
            <styles.Section>
              <Text type="BOLD" size="M" color="GRAY_1">
                {t('footer.social.title')}
              </Text>
              <styles.Link href="https://www.facebook.com/PurpoSoluciones">Facebook</styles.Link>

              <styles.Link href="https://www.instagram.com/purposoluciones/">Instagram</styles.Link>

              <styles.Link href="https://www.linkedin.com/company/purposoluciones">LinkedIn</styles.Link>
            </styles.Section>

            <styles.Section>
              <Text type="BOLD" size="M" color="GRAY_1">
                {t('footer.links.title')}
              </Text>

              {this.linksRoutes.map((menuItem, index) => (
                <div key={index}>
                  <styles.Link
                    key={index}
                    onClick={() => this.toRoute(menuItem.route)}
                    active={currentPath === menuItem.route}
                  >
                    {menuItem.label}
                  </styles.Link>
                </div>
              ))}
            </styles.Section>
          </styles.RowSections>

          <styles.Section>
            <Text type="BOLD" size="M" color="GRAY_1">
              {t('footer.contact.title')}
            </Text>

            <styles.Icon icon="8">
              <Text type="LIGHT" size="XS" color="GRAY_1">
                {t('footer.contact.phone')}
              </Text>

              <Text type="LIGHT" size="XS" color="GRAY_1">
                {t('footer.contact.phone2')}
              </Text>
            </styles.Icon>

            <styles.Icon icon="7">
              <Text type="LIGHT" size="XS" color="GRAY_1">
                {t('footer.contact.email')}
              </Text>
            </styles.Icon>

            <div>
              <Text type="LIGHT" size="XS" color="GRAY_1">
                {t('footer.contact.contact')}
              </Text>

              <styles.Link onClick={() => this.toRoute('/contacto')}>
                {t('footer.contact.link')}
              </styles.Link>
            </div>
          </styles.Section>
        </styles.Container>

        <styles.Info>
          <Text type="LIGHT" size="XS" color="GRAY_1">
            {t('footer.contact.info')}
          </Text>
        </styles.Info>
      </div>
    );
  }
}

export default withRouter(Footer);
