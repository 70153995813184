import React, { Component } from 'react';
import { Text, Input } from 'components';
import Modal from 'react-awesome-modal';
import ContactEmailService from 'services/contact-email';
import t from 'locale';
import validator from 'validator';
import styles from './styles';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      phone: '',
      email: '',
      message: '',
      processing: false,
      modal: false,
    };

    this.sendContactEmail = this.sendContactEmail.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  setValue = (key, value) => {
    this.setState({ [key]: value });
  };

  sendContactEmail() {
    const { name, phone, email, message } = this.state;
    if (!this.isValid()) return;

    this.setState({ processing: true });

    ContactEmailService(name, phone, email, message)
      .then(() => {
        this.openModal();
        this.cleanFields();
        this.hideLoader();
      })
      .catch(this.hideLoader);
  }

  hideLoader() {
    setTimeout(() => {
      this.setState({
        processing: false,
      });
    }, 600);
  }

  cleanFields() {
    this.setState({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
  }

  isValid() {
    const { name, phone, email, message } = this.state;
    return !(
      validator.isEmpty(name)
      || validator.isEmpty(phone)
      || validator.isEmpty(email)
      || validator.isEmpty(message)
      || !validator.isEmail(email)
      || !validator.isMobilePhone(phone)
    );
  }

  openModal() {
    const html = document.querySelector('html');
    html.style.overflow = 'hidden';
    window.scrollTo(0, 0);
    this.setState({ modal: true });
  }

  closeModal() {
    const html = document.querySelector('html');
    html.style.overflow = 'inherit';
    html.style.height = 'inherit';
    this.setState({ modal: false });
  }

  render() {
    return ([
      <styles.Container key={0}>
        <Text type="SEMIBOLD" size="M" color="GRAY_2">
          {this.state.processing ? t('contact.form.sending') : t('contact.form.title')}
        </Text>
        <styles.Loader visible={this.state.processing} />
        <styles.Form processing={this.state.processing}>
          <styles.InputsContainer>
            <Input
              onChange={(value) => {
                this.setValue('name', value);
              }}
              value={this.state.name}
              type="text"
              name="name"
              icon={6}
              placeholder={t('contact.form.name')}
              styles={styles.InputName}
            />

            <Input
              onChange={(value) => {
                this.setValue('phone', value);
              }}
              value={this.state.phone}
              type="text"
              name="phone"
              icon={8}
              error={
                !validator.isEmpty(this.state.phone) && !validator.isMobilePhone(this.state.phone)
                  ? t('contact.form.error.phone')
                  : ''
              }
              placeholder={t('contact.form.phone')}
              styles={styles.InputPhone}
            />
          </styles.InputsContainer>

          <Input
            onChange={(value) => {
              this.setValue('email', value);
            }}
            value={this.state.email}
            type="email"
            name="email"
            icon={7}
            error={
              !validator.isEmpty(this.state.email) && !validator.isEmail(this.state.email)
                ? t('contact.form.error.email')
                : ''
            }
            placeholder={t('contact.form.email')}
          />

          <styles.TextAreaContainer>
            <textarea
              onChange={(e) => {
                this.setValue('message', e.target.value);
              }}
              value={this.state.message}
              name="message"
              placeholder={t('contact.form.message')}
            />
          </styles.TextAreaContainer>

          <styles.InputsContainer>
            <styles.Button
              onClick={this.sendContactEmail}
              type="button"
              value={t('contact.form.summit')}
              disabled={!this.isValid()}
            />
          </styles.InputsContainer>
        </styles.Form>
      </styles.Container>,
      <Modal
        key={1}
        visible={this.state.modal}
        width="400"
        height="300"
        effect="fadeInUp"
        onClickAway={this.closeModal}
      >
        <styles.ModalContent>
          <h1>{t('contact.modal.title')}</h1>
          <p>{t('contact.modal.description')}</p>
        </styles.ModalContent>
      </Modal>,
    ]);
  }
}

export default ContactForm;
